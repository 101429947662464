import React, { Component } from "react"
import GetOfferForm from "./get-offer-form"
import { DownloadGuide } from "../components/download-guide"

export class CompareBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-bold text-2xl">Selling Your Sacramento House To Dahlia Homes vs. Listing With A Local CA Agent</h1>
                            <p className="py-2">
                                It is always a good idea to look at your options and see what tools are available to help you reach your goals with the sale of your Sacramento House.
                                While you may be able to get a higher initial sales price listing with a local Sacramento agent, that does not always translate to more money in the end. Read through
                                the details below to compare your options.
                            </p>

                            <div className="py-5">
                                <Table />
                            </div>

                            <h2 className="font-semibold text-2xl pt-5">Work The Numbers And See Which Option Benefits You To Sell Your Sacramento House</h2>
                            <p className="py-2">

                                When you really work the numbers you start to see the real benefits that each option of selling your Sacramento house offers.
                                At Dahlia Homes, we won’t be able to offer you full retail value for your house, but we offer other benefits that traditional house sales can't offer.
                            </p>

                            <p className="font-semibold text-2xl pt-5">From Offer To Close In As Little As 7 Days</p>
                            <p className="py-2">
                                Our program offers you to ability to sell your Sacramento House as fast as you want to avoid having to pay anymore utilities, taxes, insurance, or mortgage payments.
                                Listing a Sacramento House and selling the traditional way can take over 90 days, which means that you are still responsible for all costs during that time period.
                            </p>

                            <h2 className="font-semibold text-2xl pt-5">Don’t Worry About Paying Anymore Closing Fees & Costs When Selling Your Sacramento House</h2>
                            <p className="py-2">
                                Dahlia Homes pays for all of the closing costs and fees that come with selling your Sacramento Home. The offer that we make you is what you get paid (minus
                                any mortgage payoff or other encumbrances on the property).
                            </p>

                            <p className="py-2">
                                Find out why Dahlia Homes is the highest rated and most trusted Sacramento Home Buyer by filling out the form above and working with us!
                                Our process is simple and you can close on the date of your choice. Our offers are completely FREE and come with No Obligations & No Pressure!
                            </p>
                        </div>

                        <div className="mx-auto py-4">

                            <div className="flex justify-center">
                                <GetOfferForm formName="Compare Page" />
                            </div>

                            <div className="flex justify-center">
                                <DownloadGuide />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export function Table() {
    return (
        <div>
            <table className="text-sm md:text-e table-auto text-center border border-slate-500">
                <thead>
                    <tr className="bg-white">
                        <th className="border border-slate-300 px-2"> </th>
                        <th className="border border-slate-300 px-2 py-1">Selling w/ a Sacramento Agent</th>
                        <th className="border border-slate-300 px-2">Sold to Dahlia Homes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-slate-300 px-2"><b>Commissions/Fees:</b></td>
                        <td className="border border-slate-300 px-2 py-1">Up to 6% paid by you, the seller</td>
                        <td className="border border-slate-300 px-2">NONE</td>
                    </tr>
                    <tr className="bg-white">
                        <td className="border border-slate-300 px-2 py-1"><b>Who Pays Closing Costs?:</b></td>
                        <td className="border border-slate-300 px-2 py-1">2% on average is paid by you, the seller</td>
                        <td className="border border-slate-300 px-2">NONE - We pay ALL costs</td>
                    </tr>
                    <tr>
                        <td className="border border-slate-300 px-2 py-1"><b>Inspection & Financing Contingency:</b></td>
                        <td className="border border-slate-300 px-2 py-1">Yes, sales can fall through</td>
                        <td className="border border-slate-300 px-2">NONE</td>
                    </tr>
                    <tr className="bg-white">
                        <td className="border border-slate-300 px-2"><b>Appraisal Needed?:</b></td>
                        <td className="border border-slate-300 px-2 py-1">Yes, the sale is often subject to appraisal</td>
                        <td className="border border-slate-300 px-2">NONE - we make cash offers</td>
                    </tr>
                    <tr>
                        <td className="border border-slate-300 px-2 py-1"><b>Average Days Until Sold:</b></td>
                        <td className="border border-slate-300 px-2">+/-90 Days</td>
                        <td className="border border-slate-300 px-2 py-1">IMMEDIATE CASH OFFER</td>
                    </tr>
                    <tr className="bg-white">
                        <td className="border border-slate-300 px-2 py-1"><b>Number of Showings:</b></td>
                        <td className="border border-slate-300 px-2 py-1">Varies by house</td>
                        <td className="border border-slate-300 px-2">1 (Just Us)</td>
                    </tr>
                    <tr>
                        <td className="border border-slate-300 px-2 py-1"><b>Closing Date</b>:</td>
                        <td className="border border-slate-300 px-2 py-1">30-60 +/- days AFTER accepting offer</td>
                        <td className="border border-slate-300 px-2">The date of YOUR CHOICE</td>
                    </tr>
                    <tr className="bg-white">
                        <td className="border border-slate-300 px-2 py-1"><b>Who Pays for Repairs?:</b></td>
                        <td className="border border-slate-300 px-2 py-1">Negotiated During Inspection Period</td>
                        <td className="border border-slate-300 px-2 py-1">NONE - We pay for ALL repairs</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}