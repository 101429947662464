import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

export class InfoBanner extends Component {
    render() {
        return (
            <div className={this.props.bgred === true ? (`bg-dhredopaque`) : `bg-white`}>
                <div className="container mx-auto">
                    <div className="px-5 lg:px-10 grid md:grid-cols-2 xl:grid-cols-4 md:space-x-5 space-y-2 py-5 text-xs md:text-sm justify-center">
                        
                        <div className="flex items-center">
                            <StaticImage src="../images/clock.png" title="Sell Your Home Fast" alt="Sell Your Home Fast" className="w-10" />
                            <p className="pl-2">Sell Your House At Your Own Pace, Fast Or Slow</p>
                        </div>

                        <div className="flex items-center ">
                            <StaticImage src="../images/household.png" title="Sell Your Home As Is" alt="Sell Your As Is" className="w-10" />
                            <p className="pl-2">No Need To Clean Or Repair The Property, We Handle It</p>
                        </div>

                        <div className="flex items-center">
                            <StaticImage src="../images/no-fee.png" title="No Commissions" alt="No Commissions" className="w-10" />
                            <p className="pl-2">No Commissions, No Closing Costs, No Expenses</p>
                        </div>

                        <div className="flex items-center">
                            <StaticImage src="../images/bank.png" title="Instant Cash Offers" alt="Instant Cash Offers" className="w-10" />
                            <p className="pl-2">All Offers Are Made With Cash In Hand</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}