import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { CompareBody } from "../components/compare-body"
import { InfoBanner } from "../components/info-banner"
import { ThreeCards } from "../components/three-card"

const ComparePage = (props) => {
    return (
        <Layout>
            <Seo
                title="Thinking Of Selling Your House In Sacramento? Compare The Following Options"
                description="If you need to sell your house in Sacramento, get a free & no obligation offer from Dahlia Homes
                before you hire an agent or proceed the traditional way. Find out about what options you have to help you 
                sell your house in Sacramento."
                keywords={["sell your sacramento house", "selling your sacramento house"]}
                location={props.location.pathname}
            />
            <div>
                <CompareBody />

                <InfoBanner bgred={true} />

                <ThreeCards />

            </div>
        </Layout>
    )
}
export default ComparePage